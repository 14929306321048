.resultSection {
    width: 100%;
    min-height: 0;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    /*background: url( "../../assets/patterns/full-bloom.png" ) repeat;*/
    background: url("../../../assets/patterns/memphis-mini.png") repeat;
    display: flex;
    justify-content: space-evenly;
}

.resultSection > div {
    margin: 5rem 0;
}