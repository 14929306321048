.examCard {
    width: 100%;
    min-height: 7rem;
    display: flex;
    margin-bottom: 2rem;
    position: relative;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
}

.examCard:last-of-type {
   margin-bottom: 0;
}

.examCard:hover {
    cursor: pointer;
}

.examName {
    font-family: 'Cabin Sketch', cursive;
    font-size: 4rem;
    width:  10rem;
    padding-left: .45rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.timeTables {
    width: 100%;
    padding-left: .1rem;
    padding-right: .1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.written, .oral {
    display: flex;
    align-items: center;
}

.written {
    height: 3.45rem;
    color: #333;
    margin-bottom: .1rem;
    border-bottom: 1px dotted #eee;
}

.oral {
    flex-direction: column;
    list-style: none;
}

.value {
    width: 100%;
    text-align: center;
}

.oral li {
    height: 3.45rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #FCFCFC;
}

.oral li:first-child {
    background-color: #fff;
}

.oral li:nth-child(2n) {
    background-color: #F5F5F5;
}

.catName {
    width: 10rem;
    padding-left: 1rem;
    text-align: right;
}