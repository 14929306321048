@use "../../../styles/variables";

.examItem {
    width: variables.$examItemWidth;
    height: variables.$examItemHeight;

    display: flex;
    justify-content: center;
    align-items: center;
    //background-color: #ccc;
}

.examItem select, .examItem input {
    height: 2rem;
    color: #444;

    padding-left: .4rem;
    margin-right: 2rem;

    border: 1px solid #ddd;
    border-radius: .3rem;
}


.examItem label {
    margin-right: 1rem;
}