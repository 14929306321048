.calculateBtn, .resetBtn {
  height: 3.4rem;
  border: none;
}

.calculateBtn {
  font-weight: bold;
  color: white;
  border-radius: .6rem;
  letter-spacing: .1rem;
  cursor: pointer;

  padding: {
    left:  1.8rem;
    right: 1.8rem;
  };

  background: #f85032;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, #e73827, #f85032);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #e73827, #f85032); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.calculateBtn:hover {
  background: #f85032;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, #e73827, #FF9380);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #e73827, #FF9380); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.calculateBtn:disabled {
  color: white;
  cursor: auto;
  background: #eee;
}

.calculateBtn:disabled:hover {
  color: white;
  background-color: #eee;
}

.resetBtn {
  margin-right: 2rem;
  color: #aaa;
  background: none;
  font-size: 1.2rem;
  letter-spacing: .1rem;
}

.resetBtn:hover {
  cursor: pointer;
  color: red;
  text-decoration: underline;
}