.footer {
    width: 100%;
    color: #444;
    height: 5rem;
    padding-left: 5rem;
    display: flex;
    align-items: center;
}

.footer p a {
    color: #444;
    text-decoration: none;
}

.footer p a:hover {
    color: orangered;
    text-decoration: underline;
}