.optionNumber,
.examOrder,
.nextButtonBlock
{
    width: 100%;
    font-family: 'Cabin Sketch', cursive;
}

.optionNumber {
    height: 7rem;
    margin-top: 2rem;
    font-size: 3.5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    color: #f85032;  /* fallback for old browsers */
}

.examOrder {
    height: 5rem;
    text-align: center;
    font-size: 2.7rem;
    letter-spacing: .2rem;
}

.timeBlock {
    background: #76b852;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom, #8DC26F, #76b852);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #8DC26F, #76b852); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.overallDurationMin {
    height: 4.5rem;
    color: white;
    font-size: 3rem;
    display: flex;
    align-items: center;
}

.oralDurationMin p.catName {
}

.optionStartEndTime {
    width: 100%;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;

    background: #f85032;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to top, #e73827, #f85032);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to top, #e73827, #f85032); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.optionStartEndTime div {
    width: 50%;
    display: flex;
}

.optionStartEndTime p {
}


.catName {
    color: white;
    width: 7rem;
    font-size: 1.3rem;
    padding-left: 1rem;
}


.examParts {
}

.timingBlock{
    width: 100%;
    padding: 3rem 2rem 0 2rem;
    font-size: 1.1rem;
}

.svg {
    background-color: #ccc;
}

.nextButtonBlock {
    height: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: {
        top:    3.3rem;
        bottom: 3.3rem;
    };
}