.header {
    width: 100%;
    height: 14.5rem;
    /*padding-bottom: 1rem;*/
    color: #ccc;

    font-family: 'Cabin Sketch', cursive;
    font-size: 4.2rem;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    &:hover {
        cursor: pointer;
    }
}