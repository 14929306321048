@use "../../../styles/variables";

.timeProfileBox {
  width: 55rem;
  padding-left: .6rem;
  height: variables.$examItemHeight;
  display: flex;
  align-items: center;

  border-top: 1px solid variables.$greyLighter;
  //background-color: variables.$greyLighter;
  //border-radius: .8rem;

  & input, & select {
    margin-right: 2rem;
    height: variables.$examItemInputHeight;

    color: variables.$greyDark;

    border-radius: .3rem;
    border: 1px solid variables.$greyLight;
  }

  .configBar{
    &TimeInput {
      width: 5rem;
      padding: 0 0 0 1.5rem;
      margin: 0;
      text-align: center;
    }
  }
}

.timeProfileSelector {
  margin-left: .8rem;
}


.setupBtn {
  color: variables.$greyMidLight;
  border: none;
  background: none;

  &:hover {
    cursor: pointer;
    color: red;
    text-decoration: underline;
  }
}