@import url('https://fonts.googleapis.com/css2?family=Cabin+Sketch:wght@400;700&family=Open+Sans:wght@300;400;500&display=swap');

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

img {
  border-style: none;
}

html {
  box-sizing: border-box;
  font-size: 62.5%; /* 10px/16px = 62.5% -> 1rem = 10px */

/*@media only screen and (max-width: $bp-800)  {*/
/*  font-size: 56.25%;*/
/*}*/

/*@media only screen and (max-width: $bp-600)  {*/
/*  font-size: 50%;*/
/*}*/

/*@media only screen and (max-width: $bp-320)  {*/
/*  font-size: 37.5%;*/
/*}*/
}

body {
  font-family:'Open Sans', sans-serif;
  font-size: 1.4rem;
}

main {
  color: #444;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;

}