.card {
    width: 28.5rem;
    min-height: 40rem;

    background-color: #fff;

    border: none;
    border-radius: 1.4rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    position: relative;

}