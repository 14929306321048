.layout {
  background-color: rgba( 15,40,85,.5);
  border: 2px solid blue;
  width: 100vw;
  height: 100vh;

}






@media print {
  /* All your print styles go here */
  #header, #footer, #nav { display: none !important; }

  .layout {
    border: 2px solid yellow;
    width: 210mm;
    height: 297mm;

  }
}