.controlBox {
  width: 100%;
  padding: {
    //left: 2rem;
    //right: 2rem;
    left:  20%;
    right: 20%;
  };
  height: 9rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  //border: 1px solid red;
}

.left {
  //background-color: yellow;
}

.right {
  //background-color: aquamarine;

  button {
    margin-left: 2rem;
  }
}
