.writtenFieldset,
.oralBlock {
  border: 1px solid #eee;
  border-radius: .6rem;

  color: #aaa;
  input {
    color: #666;
    border: 1px solid #ddd;
    text-align: center;
  }
}

.oralBlock {
  padding: 2rem 2rem;

  fieldset {
    border: none;
    margin-bottom: 1rem;
  }

  fieldset:last-child {
    margin-bottom: 0;
  }
}

.writtenFieldset {
  padding: {
    top: 1rem;
    bottom: 2rem;
    left: 2rem;
    right: 2rem;
  }

  margin: {
    bottom: 2rem;
  }
}